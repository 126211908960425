<template>
  <a-spin :spinning="spinning">
    <a-card>
      <img slot="cover" :src="n.image ? n.image.original_url : ''" />
      <template v-if="n.file" slot="actions" class="ant-card-actions">
        <a :href="n.file.original_url" target="_blank">
          Загрузить файл {{ n.file.name }}
        </a>
      </template>
      <a-card-meta :title="n.subject" :description="n.content"></a-card-meta>
    </a-card>
    <a-card>
      <router-link :to="{ name: 'News' }">К списку новостей</router-link>
    </a-card>
  </a-spin>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "NewsView",
  components: {},
  data() {
    return {
      spinning: false,
    }
  },
  computed: {
    ...mapGetters({ n: "news/currentNews" }),
  },
  methods: {
    async loadNews() {
      this.spinning = true
      await this.$store.dispatch("news/getNews", this.$route.params.id)
      this.spinning = false
    },
  },
  mounted() {
    this.loadNews()
  },
}
</script>

<style scoped></style>
